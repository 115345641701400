<template>
  <b-form ref="form">
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('Name')" label-for="first-name">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input
                @blur="$v.personData.name.$touch()"
                :class="{ 'is-invalid': $v.personData.name.$error }"
                id="first-name"
                type="text"
                :placeholder="$t('Name')"
                v-model="personData.name"/>
            <small
                v-if="isFinal && !$v.personData.name.required"
                class="form-text text-danger"
            >{{ $t("The name field is required") }}</small
            >
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group :label="$t('Surname')" label-for="last-name">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input
                @blur="$v.personData.last_name.$touch()"
                :class="{ 'is-invalid': $v.personData.last_name.$error }"
                id="last-name"
                type="text"
                :placeholder="$t('Surname')"
                v-model="personData.last_name"/>
            <small
                v-if="isFinal && !$v.personData.last_name.required"
                class="form-text text-danger">{{ $t("The surname field is required") }}</small>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('E-Mail')" label-for="email">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="MailIcon" class="text-primary" />
            </b-input-group-prepend>
            <b-form-input
                id="email"
                v-model="personData.email"
                type="email"
                disabled/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('Phone')" label-for="phone">
          <b-input-group>
            <b-form-input
                @change="$v.personData.phone_number.$touch()"
                :class="{ 'is-invalid': $v.personData.phone_number.$error }"
                id="phone_number"
                type="number"
                v-model="personData.phone_number"
                :placeholder="$t('Phone')"/>
          </b-input-group>
          <small v-if="isFinal && !$v.personData.phone_number.required" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
          <small v-if="isFinal && !$v.personData.phone_number.numeric" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
          <small v-if="isFinal && !$v.personData.phone_number.maxLength" class="form-text text-danger" >{{ $t("The phone number field format is invalid")}}</small>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <label for="example-input"> {{ $t("Birthday") }}</label>
        <b-input-group class="mb-1">
          <flat-pickr
              v-model="personData.birthday"
              class="form-control"
              :config="config"/>
        </b-input-group>
        <small v-if="isFinal && !$v.personData.birthday.required"
               class="form-text text-danger">{{ $t("The birthday field is required") }}</small>
      </b-col>

      <b-col cols="12" md="6">
        <div>
          <label for="example-input">{{ $t("Gender") }}</label>
        </div>
        <div>
          <b-button-group>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="md"
                :variant="maleColor"
                @click="getGender('male')">
              {{ $t("Male") }}
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="femaleColor"
                size="md"
                @click="getGender('female')">
              {{ $t("Female") }}
            </b-button>
          </b-button-group>

          <small v-if="isFinal && !$v.personData.gender.numeric" class="form-text text-danger" > {{$t("Gender required")}}</small>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm,
  BRow,
  BImg,
  BAvatar,
  BCol,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BMedia,
  BInputGroupPrepend,
  BCard,
  BCardCode,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import flatPickr from 'vue-flatpickr-component'
import myUpload from 'vue-image-crop-upload/upload-2'
import Ripple from "vue-ripple-directive";

export default {
  name: 'PersonEdit',
  props: {
    personData: {
      type: Object, // person data pass
      required: true,
    },
    isFinal: {
      type: Boolean, // butona tıklandı mı final mi?
      required: true,
    },
    $v: {
      type: null, // $v validasyon özel bir prop olduğu için null olarak tanımlanmıştır.
      required: true,
    },
    maleColor: {
      type: null, //
    },
    femaleColor: {
      type: null, //
    },
    config: {
      type: null,
    },
    getGender: {
      type: Function,
    },
  },
  components: {
    // TabContent,
    BForm,
    BButton,
    BInputGroupPrepend,
    BButtonGroup,
    BAvatar,
    BFormDatepicker,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardCode,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BBreadcrumb,
    BBreadcrumbItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    'my-upload': myUpload
  },
  directives: {
    Ripple,
  },
}
</script>
